
// React.JS 17
import React from 'react';

// MUI Inputs
import Button from '@mui/material/Button';

// My Software Tab
class SoftwareDownload extends React.Component {

    render() {

        return (
            <section id="mapp3d-download">
            <div className="narrow">
            <ol>
            <li>Click the appropriate link below to download the software.</li>
            <li>Install the software.</li>
            <li>Select MY DEVICES above to obtain Reservation Code.</li>
            </ol>
            <p className="software_instructions center">
            <a href="https://mapp-3d-help.meyersound.com/install/">Complete installation instructions</a>.
            </p>
            <div>
		<h2>MAPP 3D 1.18</h2>
                <p>
                <Button href="https://mapp3d.meyersound.com/3d/download/MAPP3D_1.18.0.msi">
                Download MAPP 3D 1.18 for Windows</Button></p>
                <p>
                <Button href="https://mapp3d.meyersound.com/3d/download/MAPP3D_1.18.0_arm64.pkg">
                Download MAPP 3D 1.18 for Mac OS (ARM)</Button></p>
                <p>
                <Button href="https://mapp3d.meyersound.com/3d/download/MAPP3D_1.18.0_x86_64.pkg">
                Download MAPP 3D 1.18 for Mac OS (Intel)</Button></p>
		<h2>MAPP 3D 1.16</h2>
                <p>
                <Button href="https://mapp3d.meyersound.com/3d/download/MAPP3D_1.16.0.msi">
                Download MAPP 3D 1.16 for Windows</Button></p>
                <p>
                <Button href="https://mapp3d.meyersound.com/3d/download/MAPP3D_1.16.0_arm64.pkg">
                Download MAPP 3D 1.16 for Mac OS (ARM)</Button></p>
                <p>
                <Button href="https://mapp3d.meyersound.com/3d/download/MAPP3D_1.16.0_x86_64.pkg">
                Download MAPP 3D 1.16 for Mac OS (Intel)</Button></p>
	    </div>
            </div>
            </section>
        );
    }
}

export default SoftwareDownload;
